import settings from '@/settings'

/**
 * 下載報表
 */
class DownloadReport {
  /**
   * 學校所有教室新風日報表
   */
  ventilationSystemDailyReport(schoolId, date) {
    return axios.get(
      settings.api.fullPath +
        `/ems/download/report/daily/school/${schoolId}/ventilation-system/classroom?date=${date}`
    )
  }

  /**
   * 取得縣市該月用電量
   * 取得縣市該月用電量下載報表
   * /ems/download/report/city/consumption/monthly?cityId=8a69fc9b8066527301806662ecd70001&date=2024-05-01
   */
  cityElectricityMonthlyReport(cityId, date) {
    return axios.get(
      settings.api.fullPath +
        `/ems/download/report/city/consumption/monthly?cityId=${cityId}&date=${date}`
    )
  }

  /**
   * 取得縣市該年用電量
   * 取得縣市該年用電量下載報表
   * /ems/download/report/city/consumption/yearly?cityId=8a69fc9b8066527301806662ecd70001&year=2019
   */
  cityElectricityYearlyReport(cityId, year) {
    return axios.get(
      settings.api.fullPath +
        `/ems/download/report/city/consumption/yearly?cityId=${cityId}&year=${year}`
    )
  }
}

var downloadReport = new DownloadReport()
export default downloadReport
