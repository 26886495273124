<template>
  <div class="pa-3">
    <v-card class="search-card ems-opacity-50-bg" elevation="0">
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row align="center">
            <v-col cols="12" sm="3">
              <v-select
                v-model="query.type"
                :items="typeOptions"
                solo
                :rules="[(v) => !!v || '此欄位為必填']"
              >
                <template v-slot:label>
                  報表類型<span class="error--text">*</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <reportDateRangeSelect
                v-model="selectDates"
                :type="query.type"
                solo
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-4 pa-6" outlined @click="reset()" color="accent"
                >重設</v-btn
              >
              <v-btn
                class="py-6 px-14 accent--text"
                color="ems-aquamarine"
                @click="downloadCityUsage()"
                >報表下載</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import inputSelectMultiple from '@/components/inputSelectMultiple'
import reportDateRangeSelect from '@/components/reportDateRangeSelect'
import DownloadReport from '@/api/ems/DownloadReport'

export default {
  components: {
    inputSelectMultiple,
    reportDateRangeSelect
  },
  data: function () {
    return {
      typeOptions: [
        { value: 'monthly', text: '月報' },
        { value: 'yearly', text: '年報' }
      ],
      selectDates: null,
      query: {
        type: 'monthly'
      }
    }
  },
  computed: {
    ...mapGetters({
      tags: 'page/tags'
    }),
    cityId() {
      if (!this.tags || !this.tags.id) {
        return null
      }
      return this.tags.id
    },
    searchParams() {
      return {
        type: this.query.type,
        from: this.selectDates[0],
        to: this.selectDates[1] || this.selectDates[0]
      }
    }
  },
  methods: {
    ...mapActions({
      setOverlayLoading: 'page/setOverlayLoading'
    }),
    reset() {
      this.query = {
        type: null
      }
      this.selectDates = []
    },
    async downloadCityUsage() {
      const validate = this.$refs.form.validate()
      if (!validate) return
      this.setOverlayLoading(true)
      try {
        let exeFunc =
          this.query.type === 'monthly'
            ? DownloadReport.cityElectricityMonthlyReport(
                this.cityId,
                this.selectDates[0]
              )
            : DownloadReport.cityElectricityYearlyReport(
                this.cityId,
                moment(this.selectDates[0]).format('YYYY')
              )
        const { data } = await exeFunc
        const url = window.URL.createObjectURL(
          new Blob([(data ? '\ufeff' : '') + data], {
            type: 'text/csv;charset=utf-8;'
          })
        )
        const link = document.createElement('a')
        link.href = url
        let fileName = this.getResponseFilename()
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        console.error(error)
      } finally {
        this.setOverlayLoading(false)
      }
    },
    getResponseFilename() {
      let fileName = `${this.tags.name}${
        this.query.type === 'monthly' ? '月' : '年'
      }用電量報表`
      fileName += `_${moment(this.selectDates[0]).format(
        this.query.type === 'monthly' ? 'YYYY-MM' : 'YYYY'
      )}`
      return `${fileName}.csv`
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  .search-card {
    .v-input {
      input,
      textarea {
        color: var(--v-ems-accent-secondary-base);
      }
      &.v-text-field .v-input__control .v-input__slot {
        box-shadow: none !important;
        border-color: #a1b8d9;
        border-style: solid;
        border-width: 1px;
        ::placeholder {
          color: var(--v-ems-accent-secondary-lighten2);
        }
        .v-select__selection {
          color: var(--v-accent-base);
          font-weight: 700;
        }
      }

      &.error--text {
        &.v-text-field .v-input__control .v-input__slot {
          border-color: var(--v-error-base);
        }
      }
    }
  }
}
</style>
